import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from 'axios'
import ElementUI from 'element-ui'
import './router/auth'
import base from './base'
import 'normalize.css/normalize.css'
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false

Vue.prototype.$axios = axios
Vue.prototype.API_URL = process.env.VUE_APP_BASE_API

Vue.use(ElementUI)
Vue.use(base)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
