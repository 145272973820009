export default {
  install(Vue)  {
    
    Vue.prototype.setLocalStorage = function(key,value) {
      var curtime = new Date().getTime()
      var valueDate = JSON.stringify({
        val: value,
        timer: curtime
      });
      localStorage.setItem(key, valueDate)
    }

    Vue.prototype.getLocalStorage = function(key){
      var exp = 60 * 60 * 24 * 1000 * 30
      if (localStorage.getItem(key)) {
        var vals = localStorage.getItem(key)
        var dataObj = JSON.parse(vals)
        var isTimed = new Date().getTime() - dataObj.timer > exp
        if (isTimed) {
          localStorage.removeItem(key)
          return null
        } else {
          var newValue = dataObj.val
        }
        return newValue
      } else {
        return null
      }
    }
    
  }
}
