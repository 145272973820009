import router from '@/router'

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token')
  if (to.meta.login) {
    if (token) { 
      next()
    } else {
      next({path: '/Login'})
    }
  } else { 
    next()
  }
})
